import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import CustomButton from "../../components/CustomButton";
import { useTranslation } from "react-i18next";
import http from "../../http";
import { endpointName } from "./const";
import { useEffect, useState } from "react";
import moment from "moment";
import { apiUrl } from "../../config/settings";
import { DATE_FOR_API_FORMAT } from "../../config/const";
import TableData from "./TableData";
import { setShowLoading } from "../../store/sysSlice";
import { useDispatch } from "react-redux";

const OrderHistory = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    const start = moment().startOf("day").format(DATE_FOR_API_FORMAT);
    const end = moment().endOf("day").add(1, "day").format(DATE_FOR_API_FORMAT);
    getOrders(start, end);
  }, []);

  const getOrders = (startDate, endDate) => {
    dispatch(setShowLoading(true));
    http
      .get(`${apiUrl}/${endpointName}/history/${startDate}/${endDate}`)
      .then((res) => {
        dispatch(setShowLoading(false));
        setList(res?.data?.data);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <DialogTitle
        sx={{
          position: "sticky",
          top: 0,
          padding: 0,
          bgcolor: "white",
          zIndex: 999,
          borderBottom: "1px solid black",
        }}
      >
        <Stack spacing={2}>
          <Typography paddingX={2} paddingTop={2} variant="h6">
            {t("ORDER_HISTORY")}
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Stack gap={2} justifyContent="center">
          <TableData hideFooter filterlist={list} />
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          position: "sticky",
          bottom: 0,
          bgcolor: "white",
          zIndex: 999,
          gap: 2,
          borderTop: "1px solid black",
        }}
      >
        <Stack direction="row" gap={2} padding={1}>
          <CustomButton
            variant="outlined"
            title="CLOSE"
            onClick={() => setOpen(false)}
          />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default OrderHistory;
