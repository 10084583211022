import imageCompression from "browser-image-compression";

const useForms = () => {
  const formData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (Array.isArray(value)) {
        value.forEach((item) =>
          formData.append(`${key}[]`, JSON.stringify(item))
        );
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  };

  const imageCompressAndConvertToBase64 = async (file) => {
    if (!file) {
      throw new Error("No file provided");
    }
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(compressedFile);
      });
    } catch (err) {
      console.error("Error while compressing the image:", err);
      throw err;
    }
  };

  return { formData, imageCompressAndConvertToBase64 };
};
export default useForms;
