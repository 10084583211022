import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { endpointName } from "./const";
import TextInput from "../../components/files/TextInput";
import http from "../../http";
import { setShowLoading, setShowMassage } from "../../store/sysSlice";
import { apiUrl } from "../../config/settings";
import useDataManager from "../../hooks/useDataManager";
import { onlyNumber } from "../../utils/validations";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const Association = ({ data, setData, list, setList, cancelOrder }) => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(data);
  }, [data]);

  const onClose = () => {
    setData(null);
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));
    let obj = {
      empPath: values?.empPath,
      approximateTime: values?.approximateTime,
    };

    http
      .patch(`${apiUrl}/${endpointName}/association/${data?._id}`, obj)
      .then((res) => {
        dispatch(setShowLoading(false));
        if (res?.data?.id === 1) {
          dispatch(
            setShowMassage({ massage: "ASSOC_SUCCESS", type: "success" })
          );
          const newList = removeItem(data?._id, list);
          setList(newList);
          onClose();
        } else if (res?.data?.id === -404) {
          setValue("empPath", "");
          dispatch(
            setShowMassage({ massage: "TAXI_NOT_FOUND", type: "error" })
          );
        } else {
          dispatch(setShowMassage({ massage: "ERROR", type: "error" }));
        }
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
        setShowMassage({ massage: "ERROR", type: "error" });
      });
  };

  return (
    <Dialog
      open={data ? true : false}
      onClose={onClose}
      maxWidth="xs"
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Typography>{`${t("ASSOCIATION_DRIVER")} `}</Typography>

          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack width="100%" padding={1} spacing={1}>
            <TextInput
              width="100%"
              required
              errors={errors}
              name="empPath"
              autoFocus
              title="TAXI_NUMBER"
              control={control}
            />

            <TextInput
              width="100%"
              errors={errors}
              validation={onlyNumber}
              name="approximateTime"
              title="APPROXIMATE_TIME"
              control={control}
            />

            <Divider orientation="vertical" variant="middle" flexItem />
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            title="REJECTED"
            color="error"
            onClick={(e) => {
              cancelOrder(data._id);
            }}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Association;
