import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ROLES_ID } from "../../config/roles";
import { Add, Dvr, History, Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomMenu from "../../components/Menu";
import { useState } from "react";
import NullCheck from "../../components/NullCheck";
import CustomSearch from "../../components/CustomSearch";
import DisplaySettings from "./DisplaySettings";
import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import OrderHistory from "./OrderHistory";

const Header = ({
  getCallsList,
  setOpenCreateServices,
  setItemsList,
  setDisplaySettings,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.userSettings?.user);
  const list = useSelector((state) => state.servicesCall?.list);
  const [showSearch, setShowSearch] = useState(false);
  const [openOrderHistory, setOpenOrderHistory] = useState(false);
  const [openDisplaySettings, setOpenDisplaySettings] = useState(false);

  const menuOptions = [
    {
      title: "ORDER_HISTORY",
      icon: <History />,
      show: true,
      onClick: () => setOpenOrderHistory(true),
    },
    {
      title: "NEW_CALL",
      icon: <Add />,
      show: true,
      onClick: () => setOpenCreateServices(true),
    },
    {
      title: "IMPORT_CALL",
      icon: <AutorenewIcon />,
      show: true,
      onClick: () => getCallsList(),
    },
    {
      title: "SEARCH",
      icon: <Search />,
      show: true,
      onClick: () => {
        setShowSearch(true);
      },
    },
    {
      title: "DISPLAY_SETTINGS",
      icon: <Dvr />,
      show: true,
      onClick: () => {
        setOpenDisplaySettings(true);
      },
    },
  ];

  return (
    <Stack width="55%" direction="row" justifyContent="end">
      <Stack direction="row" spacing={1}>
        <NullCheck valueForCheck={!isMobile}>
          <IconButton onClick={() => setOpenCreateServices(true)}>
            <Add />
          </IconButton>
        </NullCheck>
        <CustomMenu options={menuOptions} />
      </Stack>

      <NullCheck valueForCheck={openDisplaySettings}>
        <DisplaySettings
          setDisplaySettings={setDisplaySettings}
          open={openDisplaySettings}
          setOpen={setOpenDisplaySettings}
        />
      </NullCheck>

      <NullCheck valueForCheck={openOrderHistory}>
        <OrderHistory open={openOrderHistory} setOpen={setOpenOrderHistory} />
      </NullCheck>

      <CustomSearch
        list={list}
        valuesSearchList={[
          "callId",
          "name",
          "tel",
          "orderNum",
          "invoiceNum",
          "clientCallNumner",
        ]}
        showDate
        callBack={(e) => setItemsList(e)}
        setOpen={setShowSearch}
        open={showSearch}
      />
    </Stack>
  );
};
export default Header;
