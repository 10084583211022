import { Stack } from "@mui/material";
import SelectInput from "../../../components/files/SelectInput";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckRole from "../../../components/CheckRole";
import { MANAGER } from "../../../config/roles";

const ClientServiceCall = ({
  errors,
  control,
  watch,
  data,
  qrId,
  clientList,
}) => {
  const employeeslist = useSelector((state) => state.biz?.data?.employees);
  const [selectedClient, setSelectedClient] = useState(null);
  const [itemList, setItemList] = useState([]);
  const clientGuid = watch("clientGuid");

  useEffect(() => {
    const findClient = clientList?.find((client) => client.guid === clientGuid);
    if (findClient) {
      const items = findClient?.items?.map((item) => {
        return { id: item?._id, name: item?.name };
      });

      setSelectedClient(findClient);
      setItemList(items);
    }
  }, [clientGuid]);

  useEffect(() => {
    if (data && clientList) {
      const findClient = clientList?.find(
        (client) => client.guid === data?.clientServiceCall?.clientGuid
      );
      if (findClient) {
        setSelectedClient(findClient);
      }
    }
  }, [data, clientList]);

  if (qrId !== "new" && !selectedClient && itemList.length === 0) return;

  return (
    <Stack spacing={2}>
      <CheckRole role={MANAGER}>
        <SelectInput
          name="empPath"
          title="EMPLOYEE"
          control={control}
          required
          defaultValue={data?.clientServiceCall?.empPath ?? ""}
          errors={errors}
          options={employeeslist?.map((emp) => {
            return { id: emp.path, name: `${emp?.fn} ${emp?.ln}` };
          })}
        />
      </CheckRole>

      <SelectInput
        name="clientGuid"
        title="CLIENT"
        control={control}
        required
        defaultValue={data?.clientServiceCall?.clientGuid ?? ""}
        errors={errors}
        options={clientList?.map((client) => {
          return {
            id: client.guid,
            name: `${client.contactName} (${client.company})`,
          };
        })}
      />

      <SelectInput
        name="clientItem"
        title="CLIENT_ITEM"
        control={control}
        // required
        defaultValue={qrId === "new" ? "" : data?.clientServiceCall?.clientItem}
        errors={errors}
        options={itemList}
      />
    </Stack>
  );
};
export default ClientServiceCall;
