import { createSlice } from "@reduxjs/toolkit";

const servicesCallSlice = createSlice({
  name: "servicesCall",
  initialState: {
    list: [],
  },
  reducers: {
    setList(state, action) {
      state.list = action.payload;
    },
  },
});

export const { setList } = servicesCallSlice.actions;
export default servicesCallSlice.reducer;
