import axios from "axios";
import { apiUrl } from "./config/settings";
import { getCookie, removeRandomChars } from "./config/functions";
import { setIsLogin } from "./store/userSettingsSlice";
import store from "./store";

const instance = axios.create({
  baseURL: `${apiUrl}`,
  // onUploadProgress: progressEvent => store.dispatch(setShowLoading(true)),
});

instance.interceptors.request.use((param) => {
  const token = getCookie("authAnalytics");
  const biz = localStorage.getItem("biz");
  param.headers.Authorization = `Bearer ${removeRandomChars(token, 4)}`;
  param.headers.BizId = `${JSON.parse(biz)?.id}`;

  return param;
});

instance.interceptors.response.use(
  (response) => {
    // store.dispatch(setShowLoading(false))
    // store.dispatch(reset());
    return response;
  },
  (err) => {
    if (err.response.status === 401) {
      store.dispatch(setIsLogin(false));
      window.history.pushState({}, "", `/Login`);
    } else {
      console.error("error", err);
      return err?.response;
    }
    // if (err.response.status === 401 &&
    //   !window.location.pathname.includes('/login/forgot-password') &&
    //   window.location.pathname !== '/login') {
    //   window.location.href = '/login';
    // }
    // if (err.response.status === 404) {
    //   history.push('/errors/error404');
    // }
    // else if (err.response.status === 401) {
    //   // Trow error again (may be needed for some other catch)
    //   return Promise.reject(err.response.status);
    // }
    // else if (err.response.status === 500) {
    //   store.dispatch(showMessage({ message: 'ERROR' }));
    // }
    // else {
    //   if (err.response?.data?.id < 0) {
    //     return err.response;
    //   }
    //   else {
    //     store.dispatch(showMessage({ message: 'ERROR' }));
    //     return err.response;
    //   }
    // }
  }
);

export default instance;
