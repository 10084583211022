import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl, boUrl, webAppUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../http";
import Details from "./Details";
import { Delete, Edit, RecentActors } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CustomDialog from "../../components/CustomDialog";
import NullCheck from "../../components/NullCheck";
import useDataManager from "../../hooks/useDataManager";
import { ROLES_ID } from "../../config/roles";
import { DataGrid } from "@mui/x-data-grid";
import {
  setDeleteMassage,
  setShowLoading,
  setShowMassage,
} from "../../store/sysSlice";
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const Employees = () => {
  const { removeItem } = useDataManager();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const bizPath = useSelector((state) => state?.biz?.data?.path);
  const maxEmployees = useSelector(
    (state) => state?.biz?.data?.plan?.maxEmployees
  );
  const [list, setList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [empDetails, setEmpDetails] = useState(null);
  const [loginEmpLink, setLoginEmpLink] = useState(false);

  useEffect(() => {
    getData();
    getDepartments();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/employees`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const getDepartments = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/departments`).then((res) => {
      setDepartments(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const empDetailsHandle = (id) => {
    setEmpDetails(id);
  };

  const deleteEmp = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_EMPLOYEES",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/employees/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <NullCheck valueForCheck={maxEmployees > 0} nullToDiv>
            <Typography>
              {isMobile
                ? `${t("QTY")}: ${list?.length} / ${maxEmployees}`
                : `${t("LINK_QTY")}: ${list?.length} ${t(
                    "OF"
                  )} ${maxEmployees}`}
            </Typography>
          </NullCheck>

          <CustomButton
            title="NEW"
            disabled={maxEmployees > 0 && list?.length >= maxEmployees}
            onClick={() => empDetailsHandle("new")}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        columns={[
          {
            field: "fn",
            headerName: t("FN"),
            width: 150,

            filterable: false,
          },
          {
            field: "ln",
            headerName: t("LN"),
            width: 150,

            filterable: false,
          },
          {
            field: "bizRole",
            headerName: t("BIZ_ROLE"),
            width: 150,

            filterable: false,
            renderCell: (value) => value.row?.bizRole ?? "",
          },
          {
            field: "tel",
            headerName: t("TEL"),
            width: 100,

            filterable: false,
          },
          {
            field: "email",
            headerName: t("EMAIL"),
            width: 200,

            filterable: false,
          },
          {
            field: "businessCard",
            headerName: t("WEN_APP_PAGE"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    window.open(
                      `${webAppUrl}/${bizPath}/${value?.row?.businessCard}`
                    );
                  }}
                >
                  <RecentActors />
                </IconButton>
              );
            },
          },
          {
            field: "loginEmpLink",
            headerName: t("LOGIN_EMP"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => setLoginEmpLink(value?.row?.userName)}
                >
                  <LockOpenIcon />
                </IconButton>
              );
            },
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    setEmpDetails(value?.row?.id);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (values) => {
              return (
                <NullCheck
                  valueForCheck={
                    values?.row?.userRole !== ROLES_ID.SU_ADMIN &&
                    values?.row?.userRole !== ROLES_ID.MANAGER
                  }
                >
                  <IconButton
                    onClick={(e) => {
                      deleteEmp(values.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </NullCheck>
              );
            },
          },
        ]}
        rows={list.map((emp) => {
          return {
            id: emp?.id,
            fn: emp?.fn,
            ln: emp?.ln,
            bizRole: emp?.bizRole,
            userRole: emp?.userRole,
            userName: emp?.userName,
            tel: emp?.tel,
            email: emp?.email,
            loginEmpLink: emp?.path,
            businessCard: emp?.path,
            edit: emp?.id,
            delete: emp?.id,
          };
        })}
      />

      {empDetails ? (
        <Details
          departments={departments}
          list={list}
          setList={setList}
          empId={empDetails}
          setEmpId={setEmpDetails}
          getData={getData}
        />
      ) : null}

      {loginEmpLink ? (
        <CustomDialog
          open={loginEmpLink ? true : false}
          onClose={() => setLoginEmpLink(false)}
          title="LOGIN_EMP_LINK"
        >
          <Stack spacing={2} alignItems="center">
            <Typography variant="body2" fontWeight="bold" color="error">
              {t("LOGIN_EMP_LINK_INFO")}
            </Typography>

            <Stack direction="row" gap={2} padding={1}>
              <CustomButton
                href={`${boUrl}/LoginEmp/${loginEmpLink}`}
                title="OPEN_LINK"
              />

              <CustomButton
                title="COPY_LINK"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${boUrl}/LoginEmp/${loginEmpLink}`
                  );
                  dispatch(
                    setShowMassage({ massage: "LINK_COPY_SUCCESSFULLY" })
                  );
                }}
              />
            </Stack>
          </Stack>
        </CustomDialog>
      ) : null}
    </CustomPage>
  );
};
export default Employees;
