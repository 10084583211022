import moment from "moment";
import { DATE_TIME_FORMAT } from "../../config/const";
import { useTranslation } from "react-i18next";
import NullCheck from "../../components/NullCheck";
import { Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import CustomButton from "../../components/CustomButton";

const GroupData = ({
  orderDetailsHandle,
  deleteOrder,
  cancelOrder,
  filterlist,
  displaySettings,
}) => {
  const { t } = useTranslation();
  const [list, setrList] = useState([]);

  useEffect(() => {
    if (displaySettings) {
      const list = Object.keys(displaySettings?.show).filter(
        (key) => displaySettings?.show[key] == true
      );
      setrList(list);
    }
  }, [displaySettings]);

  const Item = ({ title, value, fontSize, column }) => {
    return (
      <NullCheck valueForCheck={value && list.includes(column)}>
        <Stack direction="row" spacing={1}>
          <Typography fontSize={fontSize ?? 14} fontWeight="bold">
            {title ? `${t(title)}:` : ""}
          </Typography>
          <Typography
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            fontSize={fontSize ?? 14}
          >
            {value}
          </Typography>
        </Stack>
      </NullCheck>
    );
  };

  return (
    <Stack padding={2} spacing={2} direction="row" useFlexGap flexWrap="wrap">
      {filterlist
        ?.sort((a, b) => new Date(a.workDt) - new Date(b.workDt))
        ?.map((order) => {
          return (
            <Stack
              width={{ xs: "100%", md: 320 }}
              justifyContent="space-between"
              border="2px solid"
              borderColor="primary.main"
              sx={{ cursor: "pointer" }}
              onClick={() => orderDetailsHandle(order)}
            >
              <Box>
                <Stack
                  padding={1}
                  direction="row"
                  alignItems="center"
                  bgcolor="primary.main"
                >
                  <Typography color="primary.contrastText" fontWeight="bold">
                    {`${t("ORDER_TAXI")}`}
                  </Typography>
                </Stack>

                <Stack padding={1} spacing={1}>
                  <Item
                    title="COLLECTION_ADDR"
                    column="collectionAddr"
                    value={order?.collectionAddr}
                  />

                  <Item
                    title="DESTINATION_ADDR"
                    column="destinationAddr"
                    value={order?.destinationAddr}
                  />

                  <Divider sx={{ bgcolor: "primary.main" }} />
                  <Item
                    title="CONTACT_NAME"
                    column="clientName"
                    value={order?.clientName}
                  />
                  <Item
                    title="TEL"
                    column="clientTel"
                    value={order?.clientTel}
                  />
                  <Item title="TEL" column="tel" value={order?.tel} />
                </Stack>
              </Box>

              <Stack
                px={1}
                direction="row"
                borderTop="2px solid"
                borderColor="primary.main"
                alignItems="center"
                justifyContent="space-between"
                bgcolor="secondary.main"
              >
                <Typography>
                  {moment(order.created).format(DATE_TIME_FORMAT)}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
};
export default GroupData;
