import Event from "./Event";
import ServiceCall from "./ServiceCall";

const CustomEditor = ({
  item,
  setList,
  list,
  getData,
  employeeId,
  setNewDate,
}) => {
  return item.isServiceCall ? (
    <ServiceCall employeeId={employeeId} setNewDate={setNewDate} item={item} />
  ) : (
    <Event
      item={item}
      setList={setList}
      list={list}
      getData={getData}
      employeeId={employeeId}
    />
  );
};

export default CustomEditor;
