import { useEffect } from "react";
import { apiUrl } from "../config/settings";
import { setIsLogin, setUser } from "../store/userSettingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { LANGUAGES_LIST } from "../config/const";
import { setBiz } from "../store/bizSlice";
import http from "../http";
import { getCookie } from "../config/functions";
import useLogOut from "../hooks/useLogOut";

const ApiProvider = ({ children }) => {
  const { logOut } = useLogOut();
  const dispatch = useDispatch();
  const langType = useSelector((state) => state?.userSettings?.selectedLang);
  const isLogin = useSelector((state) => state?.userSettings?.isLogin);
  document.dir = LANGUAGES_LIST.find((lang) => lang.id === langType)?.dir;

  useEffect(() => {
    if (isLogin) {
      const token = getCookie("authAnalytics");
      const biz = localStorage.getItem("biz");
      const user = localStorage.getItem("user");

      if (token) {
        dispatch(setUser(JSON.parse(user)));
        dispatch(setBiz(JSON.parse(biz)));

        http
          .get(`${apiUrl}/users/info`)
          .then((response) => {
            if (response.status === 200) {
              dispatch(setIsLogin(true));
            }
          })
          .catch((error) => {
            dispatch(setIsLogin(false));
            logOut();
            console.error("Auth error");
          });
      } else {
        dispatch(setIsLogin(false));
      }
    }
  }, []);

  return children;
};

export default ApiProvider;
