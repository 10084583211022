import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "../layout";

/* pages router */
import Business from "../containers/business";
import Employees from "../containers/employees";
import Page404 from "../utils/Page404";
import Signup from "../containers/Signup";
import Login from "../containers/Login";
import { useSelector } from "react-redux";
import Dashboard from "../containers/dashboard";
import Appointments from "../containers/appointments";
import Links from "../containers/links";
import WebappMessage from "../containers/webappMessage";
import ServicesCall from "../containers/servicesCall";
import OrderTaxi from "../containers/orderTaxi";
import SmartQr from "../containers/smartQr";
import Clients from "../containers/clients";
import Reports from "../containers/reports";
import Items from "../containers/items";
import Plugins from "../containers/plugins";
import PlansManager from "../containers/admin/plans";
import Plans from "../containers/plans";
import PluginsManager from "../containers/admin/plugins";
import Departments from "../containers/departments";
import Attendance from "../containers/attendance";
import Calendar from "../containers/calendar";
import BusinessManager from "../containers/admin/business";
import ServicesCallReport from "../containers/reports/report/ServicesCallReport";
import Analytics from "../containers/Analytics";
import Recommend from "../containers/recommend";
import VideoCarousel from "../containers/videoCarousel";

const Routers = () => {
  const isLogin = useSelector((state) => state?.userSettings?.isLogin);
  return (
    <Router>
      <Layout>
        <Routes>
          {isLogin ? (
            <>
              <Route path="*" element={<Page404 />} />
              <Route index element={<Dashboard />} />
              <Route path="/" element={<Dashboard />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Biz-Settings" element={<Business />} />
              <Route path="/Links" element={<Links />} />
              <Route path="/Smart-Qr" element={<SmartQr />} />
              <Route path="/Employees" element={<Employees />} />
              <Route path="/Items" element={<Items />} />
              <Route path="/Recommend" element={<Recommend />} />
              <Route path="/Video-Carousel" element={<VideoCarousel />} />
              <Route path="/Analytics" element={<Analytics />} />
              <Route path="/Departments" element={<Departments />} />
              <Route path="/Clients" element={<Clients />} />
              <Route path="/Schedule" element={<Appointments />} />
              <Route path="/Calendar" element={<Calendar />} />
              <Route path="/Webapp-Message" element={<WebappMessage />} />
              <Route path="/Service-Call" element={<ServicesCall />} />
              <Route path="/Order-Taxi" element={<OrderTaxi />} />
              <Route path="/Attendance" element={<Attendance />} />
              <Route path="/Plans" element={<Plans />} />
              <Route path="/Plugins" element={<Plugins />} />
              <Route path="/Plugins-Manager" element={<PluginsManager />} />
              <Route path="/Plans-Manager" element={<PlansManager />} />
              <Route path="/Business-Manager" element={<BusinessManager />} />

              {/* Reports */}
              <Route path="/Reports" element={<Reports />} />
              <Route
                path="/Reports/Services-Call"
                element={<ServicesCallReport />}
              />
              <Route path="/Login" element={<Login />} />
              <Route path="/LoginEmp/:userName" element={<Login />} />
            </>
          ) : (
            <>
              {/* <Route path="*" element={<Page404 />} /> */}
              <Route index element={<Login />} />
              <Route path="/Signup" element={<Signup />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/LoginEmp/:userName" element={<Login />} />
            </>
          )}
        </Routes>
      </Layout>
    </Router>
  );
};

export default Routers;
