import AutorenewIcon from "@mui/icons-material/Autorenew";
import { ROLES_ID } from "../../config/roles";
import { useTranslation } from "react-i18next";
import { Add, Dvr, QrCode, Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CustomMenu from "../../components/Menu";
import { useState } from "react";
import NullCheck from "../../components/NullCheck";
import CustomSearch from "../../components/CustomSearch";
import CreateQr from "./createQr";
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DisplaySettings from "./DisplaySettings";

const Header = ({
  employeeId,
  selectedEmployee,
  getCallsList,
  employeeslist,
  setOpenCreateServices,
  setItemsList,
  clientsList,
  setDisplaySettings,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useSelector((state) => state.userSettings?.user);
  const list = useSelector((state) => state.servicesCall?.list);
  const [showSearch, setShowSearch] = useState(false);
  const [openCreateQr, setOpenCreateQr] = useState(false);
  const [openDisplaySettings, setOpenDisplaySettings] = useState(false);

  const menuOptions = [
    {
      title: "NEW_CALL",
      icon: <Add />,
      show: user?.userRole !== ROLES_ID.EMPLOYEES,
      onClick: () => setOpenCreateServices(true),
    },
    {
      title: "IMPORT_CALL",
      icon: <AutorenewIcon />,
      show: true,
      onClick: () => getCallsList(),
    },
    {
      title: "SEARCH",
      icon: <Search />,
      show: true,
      onClick: () => {
        setShowSearch(true);
      },
    },
    {
      title: "CREATE_QR",
      icon: <QrCode />,
      show: true,
      onClick: () => {
        setOpenCreateQr(true);
      },
    },
    {
      title: "DISPLAY_SETTINGS",
      icon: <Dvr />,
      show: true,
      onClick: () => {
        setOpenDisplaySettings(true);
      },
    },
  ];

  return (
    <Stack width="55%" direction="row" justifyContent="space-between">
      <NullCheck
        nullToDiv
        valueForCheck={user?.userRole !== ROLES_ID.EMPLOYEES}
      >
        <Select
          sx={{ width: 250 }}
          size="small"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 150,
                width: 250,
              },
            },
          }}
          value={employeeId}
          onChange={(e) => selectedEmployee(e.target.value)}
        >
          <MenuItem value={"all"}>
            <Typography fontWeight="bold">{t("ALL_CALLS")}</Typography>
          </MenuItem>
          {employeeslist?.map((emp, index) => {
            return (
              <MenuItem key={index} value={emp?.id}>
                <Typography>{`${emp.fn} ${emp.ln}`}</Typography>
              </MenuItem>
            );
          })}
        </Select>
      </NullCheck>

      <Stack direction="row" spacing={1}>
        <NullCheck valueForCheck={!isMobile}>
          <IconButton onClick={() => setShowSearch(true)}>
            <Search />
          </IconButton>
        </NullCheck>

        <NullCheck valueForCheck={!isMobile}>
          <IconButton onClick={() => setOpenCreateServices(true)}>
            <Add />
          </IconButton>
        </NullCheck>

        <CustomMenu options={menuOptions} />
      </Stack>

      <NullCheck valueForCheck={openCreateQr}>
        <CreateQr
          open={openCreateQr}
          setOpen={setOpenCreateQr}
          clientList={clientsList}
        />
      </NullCheck>

      <NullCheck valueForCheck={openDisplaySettings}>
        <DisplaySettings
          setDisplaySettings={setDisplaySettings}
          open={openDisplaySettings}
          setOpen={setOpenDisplaySettings}
        />
      </NullCheck>

      <CustomSearch
        list={list}
        valuesSearchList={[
          "callId",
          "name",
          "tel",
          "orderNum",
          "clienCompany",
          "invoiceNum",
          "clientCallNumner",
        ]}
        showDate
        callBack={(e) => setItemsList(e)}
        setOpen={setShowSearch}
        open={showSearch}
      />
    </Stack>
  );
};
export default Header;
