import { Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NullCheck from "../../../components/NullCheck";
import CustomTextLink from "../../../components/CustomTextLink";
import { findKeyByValue, listTolineText } from "../../../config/functions";
import { ST_TYPE_ID } from "../../servicesCall/const";
import { useEffect, useState } from "react";
import CustomDialog from "../../../components/CustomDialog";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import CustomIconButton from "../../../components/CustomIconButton";
import { EventRepeat } from "@mui/icons-material";

const ServiceCall = ({ item, setNewDate }) => {
  const { t } = useTranslation();
  const [openChangeDt, setOpenChangeDt] = useState(false);
  const [startDt, setStartDt] = useState(moment(item?.workDt));
  const [endDt, setEndDt] = useState(moment(item?.workEndDt));

  useEffect(() => {
    moment(item?.workDt);
    moment(item?.workEndDt);
  }, [item]);

  const onUpdateDate = () => {
    const data = {
      ...item,
      id: item.event?.id,
      workDt: moment(startDt)?._d,
      workEndDt: moment(endDt)?._d,
      start: moment(startDt)?._d,
      end: moment(endDt)?._d,
    };
    setOpenChangeDt(false);
    setNewDate(data);
  };

  return (
    <Stack minWidth={250}>
      <Stack
        width="100%"
        direction="row"
        borderBottom="2px solid black"
        padding={1}
        spacing={4}
        alignItems="center"
        justifyContent="space-between"
        sx={{ backgroundColor: item?.color }}
      >
        <Typography fontWeight="bold">
          {`${t("SERVICES_CALL")}: ${item?.callId}`}
        </Typography>

        <CustomIconButton
          size="small"
          onClick={() => setOpenChangeDt(true)}
          icon={<EventRepeat />}
        />
      </Stack>

      <Stack padding={1} spacing={1}>
        <Typography variant="body2">
          {`${t("STATUS")}: ${t(findKeyByValue(ST_TYPE_ID, item?.stTypeId))}`}
        </Typography>

        <NullCheck valueForCheck={item?.clientCallNumner}>
          <Typography variant="body2">
            {`${t("CLIENT_CALL_NUMNER")}: ${item?.clientCallNumner ?? ""}`}
          </Typography>
        </NullCheck>

        <Typography variant="body2">
          {`${t("CONTACT_NAME")}: ${item?.name}`}
        </Typography>

        <NullCheck valueForCheck={item?.addr}>
          <Typography variant="body2">
            {`${t("ADDR")}: ${item?.addr ?? ""}`}
          </Typography>
        </NullCheck>

        <CustomTextLink
          title={`${t("TEL")}: ${item?.tel}`}
          href={`tel:${item?.tel}`}
          fontSize={14}
        />

        <Divider />

        <Typography>{listTolineText(item?.contentWithItems)}</Typography>
      </Stack>

      <CustomDialog
        open={openChangeDt}
        onClose={() => setOpenChangeDt(false)}
        title="CHANGE_DATES"
        actionBtn={{ title: "SAVE", onClick: () => onUpdateDate() }}
      >
        <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
          <Stack>
            <Typography fontSize={12}>{t("START")}:</Typography>
            <DateTimePicker
              closeOnSelect
              value={startDt}
              onChange={(e) => setStartDt(e)}
            />
          </Stack>

          <Stack>
            <Typography fontSize={12}>{t("END")}:</Typography>
            <DateTimePicker
              closeOnSelect
              value={endDt}
              onChange={(e) => setEndDt(e)}
            />
          </Stack>
        </Stack>
      </CustomDialog>
    </Stack>
  );
};
export default ServiceCall;
