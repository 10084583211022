import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./Details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SMART_QR_TYPE } from "./const";
import NullCheck from "../../components/NullCheck";
import { PLUGIN_NAME } from "../../config/const";
import useDataManager from "../../hooks/useDataManager";

const SmartQr = () => {
  const { removeItem } = useDataManager();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const maxSmartQr = useSelector((state) => state?.biz?.data?.plan?.maxSmartQr);
  const plugins = useSelector((state) => state?.biz?.data?.plugins);
  const [list, setList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [qrDetails, setQrDetails] = useState(null);
  const [maxSmartQr, setMaxSmartQr] = useState(null);

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (plugins?.length > 0) {
      const plugin = plugins?.find(
        (plugin) => plugin?.sysName === PLUGIN_NAME.SMART_QR
      );
      if (plugin) {
        setMaxSmartQr(plugin?.maxQty);
      }
    }
    getData();
  }, [plugins]);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/smartQr`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const qrDetailsHandle = (id) => {
    setQrDetails(id);
  };

  const getClients = () => {
    http.get(`${apiUrl}/clients`).then((res) => {
      setClientList(res?.data?.data);
    });
  };

  const deleteQr = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_SMART_QR",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/smartQr/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <NullCheck valueForCheck={maxSmartQr > 0} nullToDiv>
            <Typography>
              {isMobile
                ? `${t("QTY")}: ${list?.length} / ${maxSmartQr}`
                : `${t("LINK_QTY")}: ${list?.length} ${t("OF")} ${maxSmartQr}`}
            </Typography>
          </NullCheck>

          <CustomButton
            title="NEW"
            disabled={maxSmartQr > 0 && list?.length >= maxSmartQr}
            onClick={() => qrDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        onRowClick={(e) => qrDetailsHandle(e?.row)}
        pageSizeOptions={[100]}
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        // rowSelection={() => empDetailsHandle("ie")}
        columns={[
          {
            field: "title",
            headerName: t("TITLE"),
            width: 150,

            filterable: false,
          },
          {
            field: "type",
            headerName: t("TYPE"),
            flex: 10,

            filterable: false,
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,

            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setQrDetails(value?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,

            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteQr(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list.map((qr) => {
          return {
            ...qr,
            id: qr?._id,
            title: qr?.title,
            type: `${t(
              SMART_QR_TYPE?.find((type) => type?.id === qr?.typeId)?.name
            )}`,
          };
        })}
      />

      {qrDetails ? (
        <Details
          list={list}
          setList={setList}
          clientList={clientList}
          qrDetails={qrDetails}
          setQrDetails={setQrDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default SmartQr;
