export let apiUrl = "";
export let webAppUrl = "";
export let boUrl = "";
export const imageUrl = "https://mybee-server.onrender.com";

export const SITE_KEY_V3 = "6LcQIzgqAAAAAFbBxApOttqBJeteXUe8N69rg9de";
export const SITE_KEY_V2 = "6LeN_zcqAAAAAKdz6GpxoURmaecThbf_gRQUunLa";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  apiUrl = "http://localhost:3000";
  webAppUrl = "http://localhost:3002";
  boUrl = "http://localhost:3001";
  // dev code
} else {
  apiUrl = "https://mybee-server.onrender.com";
  webAppUrl = "https://mybee.co.il";
  boUrl = "https://mybee-bo.onrender.com";
  // production code
}
