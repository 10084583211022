import { useTranslation } from "react-i18next";
import { CalendarMonth, GetApp } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";
import { useEffect, useState } from "react";
import { DATE_TIME_FORMAT } from "../../config/const";
import moment from "moment";
import { useSelector } from "react-redux";
import { PMT_TYPE, ST_TYPE } from "./const";

const Header = ({ list, setDatesReports }) => {
  const { t } = useTranslation();
  const employeeslist = useSelector((state) => state.biz?.data?.employees);
  const [listToExport, setListToExport] = useState([]);

  useEffect(() => {
    if (list?.length > 0) {
      const newList = list?.map((item) => {
        const status = `${t(
          ST_TYPE.find((st) => st?.id === item?.stTypeId)?.name
        )}`;
        const pmtStatus = `${t(
          PMT_TYPE.find((st) => st?.id === item?.pmtTypeId)?.name
        )}`;
        const employees = employeeslist?.find(
          (emp) => emp?.id === item?.employee
        );

        return {
          "מספר קריאת שרות": item?.callId,
          "מספר קריאת (לקוח)": item?.clientCallNumner,
          "תאריך פתיחה": moment(item?.created).format(DATE_TIME_FORMAT),
          "תאריך ביצוע העבודה": moment(item?.workDt).format(DATE_TIME_FORMAT),
          "עובד משוייך": `${employees?.fn} ${employees?.ln}`,
          "מזהה לקוח": item?.clientId,
          "טלפון איש קשר": item?.tel,
          "שם איש קשר": item?.name,
          "מספר הזמנה": item?.orderNum,
          "מספר חשבונית": item?.invoiceNum,
          " כתובת": item?.addr,
          " סטטוס ": status,
          " סטטוס חיוב ": pmtStatus,
          " מחיר": item?.price,
          "עבודה שבוצעה": item?.jobDoneDesc,
          " הערות": item?.notes,
        };
      });

      setListToExport(newList);
    }
  }, [list]);

  const exportToExcel = () => {
    let worksheet = XLSX.utils.json_to_sheet(listToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `MyBee-Repords.xlsx`);
    // saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <Stack width="55%" direction="row" justifyContent="end">
      <IconButton onClick={() => setDatesReports(true)}>
        <CalendarMonth />
      </IconButton>
      <IconButton disabled={list?.length === 0} onClick={exportToExcel}>
        <GetApp />
      </IconButton>
    </Stack>
  );
};
export default Header;
