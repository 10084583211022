import { Stack, TextField, Typography } from "@mui/material";
import CustomDialog from "./CustomDialog";
import { useState } from "react";
import CustomButton from "./CustomButton";
import NullCheck from "./NullCheck";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT } from "../config/const";
import moment from "moment";

const CustomSearch = ({
  open,
  setOpen,
  list,
  callBack,
  valuesSearchList,
  showDate,
}) => {
  const { t } = useTranslation();
  const [textSearch, setTextSearch] = useState("");
  const [dateSearch, setDateSearch] = useState(null);

  const searchHandle = () => {
    if (list?.length > 0 && valuesSearchList?.length > 0) {
      let filterData = textSearch === "" ? [...list] : [];
      if (textSearch !== "") {
        filterData = list.filter((item) =>
          valuesSearchList?.some((value) =>
            item[value]?.toString().includes(textSearch)
          )
        );
      }
      if (dateSearch) {
        filterData = list.filter(
          (item) =>
            moment(item?.workDt).format(DATE_FORMAT) ===
            moment(dateSearch).format(DATE_FORMAT)
        );
      }
      callBack(filterData);
      setOpen(false);
    }
  };

  const resetHandle = () => {
    setTextSearch("");
    callBack(list);
    setOpen(false);
  };

  return (
    <CustomDialog title="SEARCH" onClose={() => setOpen(false)} open={open}>
      <Stack spacing={2}>
        <TextField
          size="small"
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
        />
        <NullCheck valueForCheck={showDate}>
          <Stack>
            <Typography fontSize={12}>{t("DATE")}:</Typography>
            <DatePicker
              // maxDate={moment()}
              value={dateSearch}
              onChange={(e) => setDateSearch(e)}
            />
          </Stack>
        </NullCheck>

        <Stack spacing={1}>
          <CustomButton title="SEARCH" onClick={searchHandle} />
          <CustomButton title="RESET" onClick={resetHandle} />
        </Stack>
      </Stack>
    </CustomDialog>
  );
};
export default CustomSearch;
