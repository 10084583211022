import TextInput from "../../components/files/TextInput";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import http from "../../http";
import { setShowLoading } from "../../store/sysSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../config/settings";
import { useTranslation } from "react-i18next";
import { onlyNumber } from "../../utils/validations";
import CustomButton from "../../components/CustomButton";
import SelectInput from "../../components/files/SelectInput";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import NullCheck from "../../components/NullCheck";
import { endpointName } from "./const";
import { Close } from "@mui/icons-material";
import CustomIconButton from "../../components/CustomIconButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

const CustomEditor = ({ item, clientList, getData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const employeeslist = useSelector((state) => state.biz?.data?.employees);
  const [selectedClient, setSelectedClient] = useState(null);
  const [itemList, setItemList] = useState([]);
  const [startDt, setStartDt] = useState(null);
  const [endDt, setEndDt] = useState(null);
  const clientGuid = watch("clientGuid");

  useEffect(() => {
    const start = item?.startDt ?? item?.state?.start?.value;
    const end = item?.endDt ?? item?.state?.end?.value;
    setStartDt(moment(start));
    setEndDt(moment(end));

    reset({
      // ...item,
      employee: employeeslist.find((emp) => emp?.id === item?.employee),
    });
  }, [item]);

  useEffect(() => {
    if (clientGuid) {
      const findClient = clientList?.find(
        (client) => client.guid === clientGuid
      );
      if (findClient) {
        const items = findClient?.items?.map((item) => {
          return { id: item?._id, name: item?.name };
        });

        setValue("addr", findClient?.addr);
        setValue("tel", findClient?.tel);

        setSelectedClient(findClient);
        setItemList(items);
      }
    }
  }, [clientGuid]);

  const onClose = () => {
    item?.close();
  };

  const deleteItem = () => {
    dispatch(setShowLoading(true));
    http
      .delete(`${apiUrl}/${endpointName}/${item?._id}`)
      .then((res) => {
        dispatch(setShowLoading(false));
        getData();
        onClose();
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));

    let data = {
      startDt: moment(startDt),
      endDt: moment(endDt),
      clientName: selectedClient?.contactName,
      clientId: values?.clientGuid,
      clientItem: values?.clientItem === "" ? null : values?.clientItem,
      tel: selectedClient?.tel,
      addr: selectedClient?.addr,
      employee: values?.employee,
    };

    // if (item?.id !== "new") {
    //   http
    //     .patch(`${apiUrl}/${endpointName}/${item?.id}`, data)
    //     .then((res) => {
    //       dispatch(setShowLoading(false));
    //       getData();
    //       onClose();
    //     })
    //     .catch((error) => {
    //       dispatch(setShowLoading(false));
    //     });
    // }
    //  else {

    http
      .post(`${apiUrl}/${endpointName}`, data)
      .then((res) => {
        dispatch(setShowLoading(false));
        getData();
        onClose();
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
    // }
  };

  if (!item || clientList.length === 0) return;

  return (
    <Dialog
      open={item ? true : false}
      onClose={onClose}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{t("LINK_DETAILS")}</Typography>
            <CustomIconButton size={30} icon={<Close />} onClick={onClose} />
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          <Stack width="100%" gap={2}>
            <NullCheck valueForCheck={employeeslist.length > 1}>
              <Stack
                width="100%"
                gap={2}
                direction={{ xs: "column", md: "row" }}
              >
                <SelectInput
                  name="employee"
                  title="EMPLOYEE"
                  control={control}
                  required
                  errors={errors}
                  options={employeeslist.map((emp) => {
                    return { id: emp.path, name: `${emp?.fn} ${emp?.ln}` };
                  })}
                />
              </Stack>
            </NullCheck>

            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <SelectInput
                name="clientItem"
                title="CLIENT_ITEM"
                control={control}
                // defaultValue={
                //   item === "new" ? "" : data?.clientServiceCall?.clientItem
                // }
                errors={errors}
                options={itemList}
              />

              <SelectInput
                name="clientGuid"
                title="CLIENT"
                control={control}
                required
                errors={errors}
                options={clientList?.map((client) => {
                  return { id: client.guid, name: client.contactName };
                })}
              />
            </Stack>

            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <TextInput
                name="tel"
                title="TEL"
                width="100%"
                validation={onlyNumber}
                errors={errors}
                control={control}
              />

              <TextInput
                name="addr"
                title="ADDR"
                width="100%"
                errors={errors}
                control={control}
              />
            </Stack>

            <Stack width="100%" gap={2} direction={{ xs: "column", md: "row" }}>
              <Stack>
                <Typography fontSize={12}>{t("START_DATE")}:</Typography>
                <DateTimePicker
                  minDate={moment()}
                  value={startDt}
                  onChange={(e) => setStartDt(e)}
                />
              </Stack>

              <Stack>
                <Typography fontSize={12}>{t("END_DATE")}:</Typography>
                <DateTimePicker
                  minDate={moment()}
                  value={endDt}
                  onChange={(e) => setEndDt(e)}
                />
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ gap: 2 }}>
          <CustomButton
            variant="outlined"
            title="DELETE"
            color="error"
            autoFocus
            onClick={deleteItem}
          />
          <CustomButton submit title="SAVE" />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CustomEditor;
