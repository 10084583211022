import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import Details from "./Details";
import { DEPARTMENT_MANAGER, ROLES_ID } from "../../config/roles";
import { DATE_FORMAT } from "../../config/const";
import moment from "moment/moment";
import { endpointNmae } from "./const";
import { setList } from "../../store/servicesCallSlice";
import { Stack, Tab, Tabs } from "@mui/material";
import CreateServices from "./create";
import Header from "./Header";
import useDataManager from "../../hooks/useDataManager";
import TableData from "./TableData";
import GroupData from "./GroupData";

const ServicesCall = () => {
  const { removeItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);
  const employeeslist = useSelector((state) => state?.biz?.data?.employees);
  const data = useSelector((state) => state?.servicesCall.list);
  const [itemsList, setItemsList] = useState([]);
  const [filterlist, setFilterlist] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [servicesCallDetails, setServicesCallDetails] = useState(null);
  const [openCreateServices, setOpenCreateServices] = useState(false);
  const [employeeId, setEmployeeId] = useState("all");
  const [selectedTab, setSelectedTab] = useState(1);
  const [workDt, setWorkDt] = useState(0);
  const [openQty, setOpenQty] = useState(0);
  const [closeQty, setCloseQty] = useState(0);
  const [waitingQty, setWaitingQty] = useState(0);
  const [displaySettings, setDisplaySettings] = useState(null);

  useEffect(() => {
    getClientList();

    let settings = localStorage.getItem("displaySettings");
    settings = JSON.parse(settings);
    if (settings) {
      setDisplaySettings(settings);
    } else {
      const data = {
        type: "cards",
        show: {
          name: true,
          tel: true,
          addr: true,
          notes: true,
          workDt: true,
          clientCallNumner: true,
        },
      };
      setDisplaySettings(data);
      localStorage.setItem("displaySettings", JSON.stringify(data));
    }
  }, []);

  useEffect(() => {
    if (user?.userRole === ROLES_ID.EMPLOYEES) {
      setEmployeeId(user?.id);
      getEmpCallsList(user?.id);
    } else {
      getCallsList();
    }
  }, [user]);

  useEffect(() => {
    filterDataTab();
  }, [selectedTab]);

  useEffect(() => {
    callTypeIdQty();
    filterDataTab();
    // selectedEmployee(employeeId);
  }, [itemsList]);

  useEffect(() => {
    setItemsList(data);
  }, [data]);

  const callTypeIdQty = () => {
    setOpenQty(itemsList?.filter((call) => call.stTypeId === 1)?.length);
    setCloseQty(itemsList?.filter((call) => call.stTypeId === 2)?.length);
    setWaitingQty(itemsList?.filter((call) => call.stTypeId === 3)?.length);
    setWorkDt(
      itemsList?.filter(
        (call) =>
          call.stTypeId === 1 &&
          call.workDt &&
          moment(call.workDt).format(DATE_FORMAT) ===
            moment().format(DATE_FORMAT)
      )?.length
    );
  };

  const filterDataTab = () => {
    let list = [];
    if (selectedTab === 4) {
      list = itemsList?.filter(
        (call) =>
          call.stTypeId === 1 &&
          call.workDt &&
          moment(call.workDt).format(DATE_FORMAT) ===
            moment().format(DATE_FORMAT)
      );
    } else {
      list = itemsList?.filter((call) => call.stTypeId === selectedTab);
    }
    setFilterlist(list);
  };

  const getCallsList = () => {
    dispatch(setShowLoading(true));

    if (user?.userRole === ROLES_ID.EMPLOYEES) {
      http.get(`${apiUrl}/${endpointNmae}/${employeeId}`).then((res) => {
        dispatch(setList(res?.data?.data));
        setItemsList(res?.data?.data);
        dispatch(setShowLoading(false));
      });
    } else if (DEPARTMENT_MANAGER.includes(user?.userRole)) {
      http.get(`${apiUrl}/${endpointNmae}`).then((res) => {
        dispatch(setList(res?.data?.data));
        setItemsList(res?.data?.data);
        dispatch(setShowLoading(false));
      });
    }
  };

  const getEmpCallsList = (id) => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointNmae}/${id}`).then((res) => {
      dispatch(setList(res?.data?.data));
      setItemsList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const getClientList = () => {
    http
      .get(`${apiUrl}/clients`)
      .then((res) => {
        setClientsList(res?.data?.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const selectedEmployee = (id) => {
    const empId = id;
    setEmployeeId(empId);
    if (empId !== "all") {
      setItemsList(data?.filter((call) => call.employee === empId));
    } else {
      setItemsList(data);
    }
  };

  const serviceCallDetailsHandle = (id) => {
    setServicesCallDetails(id);
  };

  const deleteServicesCall = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_SERVICES_CALL",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              const newList = removeItem(id, data);
              dispatch(setList(newList));
              dispatch(setShowLoading(false));
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Header
          setDisplaySettings={setDisplaySettings}
          clientsList={clientsList}
          setItemsList={setItemsList}
          employeeId={employeeId}
          selectedEmployee={selectedEmployee}
          getCallsList={getCallsList}
          employeeslist={employeeslist}
          emplist={filterlist}
          setOpenCreateServices={setOpenCreateServices}
        />
      }
    >
      <Stack height="100%">
        <Tabs
          variant="scrollable"
          value={selectedTab}
          onChange={handleChangeTab}
        >
          <Tab value={1} label={`${t("OPEN")} (${openQty})`} />
          <Tab value={2} label={`${t("CLOSE")} (${closeQty})`} />
          <Tab value={3} label={`${t("WAITING")} (${waitingQty})`} />
          <Tab value={4} label={`${t("DODAY")} (${workDt})`} />
        </Tabs>

        {displaySettings?.type === "table" ? (
          <TableData
            displaySettings={displaySettings}
            clientsList={clientsList}
            serviceCallDetailsHandle={serviceCallDetailsHandle}
            deleteServicesCall={deleteServicesCall}
            filterlist={filterlist}
          />
        ) : (
          <GroupData
            displaySettings={displaySettings}
            clientsList={clientsList}
            serviceCallDetailsHandle={serviceCallDetailsHandle}
            deleteServicesCall={deleteServicesCall}
            filterlist={filterlist}
          />
        )}
      </Stack>

      {servicesCallDetails ? (
        <Details
          list={itemsList}
          data={servicesCallDetails}
          setData={setServicesCallDetails}
          getData={getCallsList}
        />
      ) : null}

      {openCreateServices ? (
        <CreateServices
          list={itemsList}
          clients={clientsList}
          open={openCreateServices}
          setOpen={setOpenCreateServices}
        />
      ) : null}
    </CustomPage>
  );
};
export default ServicesCall;
