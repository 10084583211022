import { useState } from "react";
import "moment/locale/he";
import { DatePicker } from "@mui/x-date-pickers";
import CustomDialog from "../../components/CustomDialog";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import { Divider, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { PMT_TYPE, ST_TYPE } from "./const";

const DatesReports = ({ open, setOpen, callBack, clientsList }) => {
  const { t } = useTranslation();
  const employeeslist = useSelector((state) => state?.biz?.data?.employees);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [selectedEmployee, setSelectedEmployee] = useState("all");
  const [selectedClients, setSelectedClients] = useState("all");
  const [selectedPmtType, setSelectedPmtType] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState("all");

  const callBackDate = () => {
    callBack({
      startDate: moment(startDate).startOf("day"),
      endDate: moment(endDate).endOf("day"),
      selectedEmployee,
      selectedClients,
      selectedPmtType,
      selectedStatus,
      selectedInvoiceStatus,
    });
    setOpen(false);
  };

  return (
    <CustomDialog
      title="DATES"
      onClose={() => setOpen(false)}
      open={open}
      actionBtn={{
        title: "CREATE_REPORT",
        onClick: () => callBackDate(),
      }}
    >
      {/* <Stack direction="row" gap={2}> */}
      <Stack direction={{ xs: "column", md: "row" }} gap={2}>
        <Stack spacing={2}>
          <CustomButton
            title="TODAY"
            onClick={() => {
              setStartDate(moment());
              setEndDate(moment());
            }}
          />
          <CustomButton
            title="THIS_WEEK"
            onClick={() => {
              setStartDate(moment().startOf("week"));
              setEndDate(moment());
            }}
          />

          <CustomButton
            title="THIS_MONTH"
            onClick={() => {
              setStartDate(moment().startOf("month"));
              setEndDate(moment());
            }}
          />
          <CustomButton
            title="THIS_YEAR"
            onClick={() => {
              setStartDate(moment().startOf("year"));
              setEndDate(moment());
            }}
          />
        </Stack>

        <Divider orientation="vertical" flexItem />

        {/* <Stack spacing={2} direction={{ xs: "column", md: "row" }}> */}
        <Stack spacing={2}>
          <Stack>
            <Typography fontSize={12}>{t("START_DATE")}:</Typography>
            <DatePicker
              maxDate={moment()}
              value={startDate}
              onChange={(e) => setStartDate(e)}
            />
          </Stack>

          <Stack>
            <Typography fontSize={12}>{t("END_DATE")}:</Typography>
            <DatePicker
              maxDate={moment()}
              value={endDate}
              onChange={(e) => setEndDate(e)}
            />
          </Stack>
        </Stack>

        <Divider orientation="vertical" flexItem />

        <Stack spacing={2}>
          {/* select employee */}
          <Stack>
            <Typography fontSize={12}>{t("EMPLOYEE")}:</Typography>
            <Select
              sx={{ width: 250 }}
              size="small"
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
            >
              <MenuItem value={"all"}>
                <Typography>{t("ALL_EMPLOYEES")}</Typography>
              </MenuItem>
              {employeeslist?.map((emp, index) => {
                return (
                  <MenuItem key={index} value={emp?.id}>
                    <Typography>{`${emp.fn} ${emp.ln}`}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>

          {/* select client */}
          <Stack>
            <Typography fontSize={12}>{t("CLIENTS")}:</Typography>
            <Select
              sx={{ width: 250 }}
              size="small"
              value={selectedClients}
              onChange={(e) => setSelectedClients(e.target.value)}
            >
              <MenuItem value={"all"}>
                <Typography>{t("ALL_CLIENTS")}</Typography>
              </MenuItem>
              {clientsList?.map((client, index) => {
                return (
                  <MenuItem key={index} value={client?.guid}>
                    <Typography>
                      {client.company ? client.company : client.contactName}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>

          {/* select status */}
          <Stack>
            <Typography fontSize={12}>{t("STATUS_TYPE")}:</Typography>
            <Select
              sx={{ width: 250 }}
              size="small"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <MenuItem value={"all"}>
                <Typography>{t("ALL_STATUS")}</Typography>
              </MenuItem>
              {ST_TYPE?.map((st, index) => {
                return (
                  <MenuItem key={index} value={st?.id}>
                    <Typography>{t(st.name)}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>

          {/* select pmt status */}
          <Stack>
            <Typography fontSize={12}>{t("PMT_TYPE")}:</Typography>
            <Select
              sx={{ width: 250 }}
              size="small"
              value={selectedPmtType}
              onChange={(e) => setSelectedPmtType(e.target.value)}
            >
              <MenuItem value={"all"}>
                <Typography>{t("ALL_PMT")}</Typography>
              </MenuItem>
              {PMT_TYPE?.map((pmt, index) => {
                return (
                  <MenuItem key={index} value={pmt?.id}>
                    <Typography>{t(pmt.name)}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Stack>

          {/* invoice status */}
          <Stack>
            <Typography fontSize={12}>{t("INVOICE_STATUS")}:</Typography>
            <Select
              sx={{ width: 250 }}
              size="small"
              value={selectedInvoiceStatus}
              onChange={(e) => setSelectedInvoiceStatus(e.target.value)}
            >
              <MenuItem value={"all"}>
                <Typography>{t("ALL")}</Typography>
              </MenuItem>
              <MenuItem value={"full"}>
                <Typography>{t("FULL")}</Typography>
              </MenuItem>
              <MenuItem value={"empty"}>
                <Typography>{t("EMPTY")}</Typography>
              </MenuItem>
            </Select>
          </Stack>
        </Stack>
      </Stack>
      {/* </Stack> */}
    </CustomDialog>
  );
};
export default DatesReports;
