import moment from "moment";
import CustomDataGrid from "../../components/CustomDataGrid";
import { DATE_TIME_FORMAT } from "../../config/const";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NullCheck from "../../components/NullCheck";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES_ID } from "../../config/roles";
import { currencyFormat } from "../../config/functions";

const TableData = ({
  clientsList,
  serviceCallDetailsHandle,
  deleteServicesCall,
  filterlist,
  displaySettings,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);

  return (
    <CustomDataGrid
      defaultSort={[{ field: "workDt", sort: "asc" }]}
      setItem={serviceCallDetailsHandle}
      columnVisibilityModel={displaySettings.show}
      columns={[
        {
          field: "callId",
          headerName: t("CALL_ID"),
          width: 100,

          filterable: false,
        },
        {
          field: "name",
          headerName: t("CONTACT_NAME"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row.name ? `${value?.row.name}` : "";
          },
        },
        {
          field: "company",
          headerName: t("COMPANY"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            const findClient = clientsList?.find(
              (client) =>
                parseInt(client?.guid) === parseInt(value?.row.clientId)
            );
            return findClient?.company ? `${findClient?.company}` : "";
          },
        },
        {
          field: "addr",
          headerName: t("ADDR"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.addr ?? "";
          },
        },
        {
          field: "tel",
          headerName: t("TEL"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.tel ?? "";
          },
        },
        {
          field: "notes",
          headerName: t("NOTES"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.notes ?? "";
          },
        },
        {
          field: "price",
          headerName: t("PRICE"),
          width: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.price ? currencyFormat(value?.row?.price) : "";
          },
        },
        {
          field: "clientCallNumner",
          headerName: t("CLIENT_CALL_NUMNER"),
          width: 150,
          filterable: false,
        },
        {
          field: "workDt",
          headerName: t("WORK_DT"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row.workDt
              ? moment(value?.row.workDt).format(DATE_TIME_FORMAT)
              : "";
          },
        },
        {
          field: "providers",
          headerName: t("PROVIDERS"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.providers ?? "";
          },
        },
        {
          field: "contentWithItems",
          headerName: t("CONTENT_WITH_ITEMS"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.contentWithItems ?? "";
          },
        },
        {
          field: "openCall",
          headerName: t("OPEN"),
          width: 80,

          filterable: false,
          renderCell: (value) => {
            return (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  serviceCallDetailsHandle(value?.row);
                }}
              >
                <FileOpenIcon />
              </IconButton>
            );
          },
        },
        {
          field: "delete",
          headerName: t("DELETE"),
          flex: 80,
          filterable: false,
          renderCell: (row) => {
            return (
              <NullCheck valueForCheck={user?.userRole !== ROLES_ID.EMPLOYEES}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteServicesCall(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </NullCheck>
            );
          },
        },
      ]}
      list={filterlist?.map((call) => {
        return {
          ...call,
          id: call?._id,
          callId: call?.callId,
          clientCallNumner: call?.clientCallNumner,
          name: call?.name,
          tel: call?.tel,
          workDt: call?.workDt,
          content: call?.content,
          delete: call?.id,
        };
      })}
    />
  );
};
export default TableData;
