import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../http";
import { apiUrl } from "../../../config/settings";
import { useEffect, useState } from "react";
import ClientItems from "./ClientItems";
import ClientDetails from "./ClientDetails";
import SelectInput from "../../../components/files/SelectInput";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import useDataManager from "../../../hooks/useDataManager";
import { setList } from "../../../store/servicesCallSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { setShowLoading } from "../../../store/sysSlice";

const CreateServices = ({ open, setOpen, clients, list }) => {
  const { addItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const employeeslist = useSelector((state) => state?.biz?.data?.employees);
  const [clientData, setClientData] = useState(null);
  const [workDt, setWorkDt] = useState(null);
  const [clientsData, setClientsData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const clientId = watch("clientId");

  useEffect(() => {
    const findClient = clientsData?.find((client) => client?.guid === clientId);
    setClientData(findClient);
    setValue("addr", findClient?.addr);
    setValue("name", findClient?.contactName);
    setValue("tel", findClient?.tel);
  }, [clientId]);

  useEffect(() => {
    const list = clients?.map((client) => {
      return {
        name: `${client?.contactName} ${
          client?.company ? `(${client?.company})` : ""
        }`,
        id: client?.guid,
      };
    });
    setClientList(list);
    setClientsData(clients);
  }, [clients]);

  const onSubmit = (values) => {
    if (itemList.length === 0) return alert("יש להוסיף לפחות מוצר אחד לרשימה");

    dispatch(setShowLoading(true));
    let data = {
      employeeId: values?.empId,
      clientId: values.clientId ?? "",
      clienCompany: clientData?.company,
      contentWithItems: itemList,
      workDt: workDt,
      ...values,
    };

    http
      .post(`${apiUrl}/servicesCall`, data)
      .then((res) => {
        data._id = res?.data?.itemId;
        data.callId = res?.data?.callId;
        data.employee = values?.empId;
        data.pmtTypeId = 1;
        data.stTypeId = 1;
        data.price = 0;
        data.created = moment();

        const newList = addItem(data, list);
        dispatch(setList(newList));
        dispatch(setShowLoading(false));
        setOpen(false);
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            padding: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Stack spacing={2}>
            <Typography paddingX={2} paddingTop={2} variant="h6">
              {t("CREATE_SERVICES")}
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ minHeight: { xs: "70vh", md: 400 } }}>
          <Stack padding={1} gap={2} justifyContent="center">
            <Stack spacing={2}>
              <SelectInput
                width={{ xs: "100%", md: "auto" }}
                errors={errors}
                required
                options={employeeslist?.map((emp) => {
                  return { name: `${emp.fn} ${emp.ln}`, id: emp?.id };
                })}
                name="empId"
                title="SELECT_EMP"
                control={control}
              />

              <Stack>
                <Typography fontSize={12}>{t("WORK_DT")}:</Typography>
                <DateTimePicker
                  minDate={moment()}
                  value={workDt}
                  onChange={(e) => setWorkDt(e)}
                />
              </Stack>

              <ClientDetails
                clientList={clientList}
                control={control}
                errors={errors}
              />
              <ClientItems
                clientId={clientId}
                itemList={itemList}
                setItemList={setItemList}
                clientData={clientData}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <Stack direction="row" gap={2} padding={1}>
            <CustomButton
              variant="outlined"
              title="CLOSE"
              onClick={() => setOpen(false)}
            />
            <CustomButton loading title="SEND" submit />
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CreateServices;
