import CustomDataGrid from "../../components/CustomDataGrid";
import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NullCheck from "../../components/NullCheck";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES_ID } from "../../config/roles";

const TableData = ({
  orderDetailsHandle,
  deleteOrder,
  filterlist,
  hideFooter,
  displaySettings,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.userSettings?.user);

  const onClick = (order) => {
    if (orderDetailsHandle) {
      orderDetailsHandle(order);
    }
  };

  return (
    <CustomDataGrid
      defaultSort={[{ field: "workDt", sort: "asc" }]}
      setItem={(order) => onClick(order.row)}
      hideFooter={hideFooter ? true : false}
      columnVisibilityModel={displaySettings ? displaySettings.show : false}
      columns={[
        {
          field: "clientName",
          headerName: t("CONTACT_NAME"),
          width: 150,
          filterable: false,
          renderCell: (value) => {
            return value?.row.name ? `${value?.row.clientName}` : "";
          },
        },
        {
          field: "clientTel",
          headerName: t("TEL"),
          wwidth: 200,
          filterable: false,
          renderCell: (value) => {
            return value?.row?.clientTel ?? "";
          },
        },

        {
          field: "openCall",
          headerName: t("OPEN"),
          width: 80,

          filterable: false,
          renderCell: (value) => {
            return (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onClick(value?.row);
                }}
              >
                <FileOpenIcon />
              </IconButton>
            );
          },
        },
        {
          field: "delete",
          headerName: t("DELETE"),
          with: 80,
          filterable: false,
          renderCell: (row) => {
            return (
              <NullCheck valueForCheck={user?.userRole !== ROLES_ID.EMPLOYEES}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteOrder(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              </NullCheck>
            );
          },
        },
      ]}
      list={filterlist?.map((call) => {
        return {
          ...call,
          id: call?._id,
          delete: call?.id,
        };
      })}
    />
  );
};
export default TableData;
