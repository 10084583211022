import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import http from "../../../http";
import { apiUrl } from "../../../config/settings";
import { useEffect, useState } from "react";
import ClientDetails from "./ClientDetails";
import SelectInput from "../../../components/files/SelectInput";
import moment from "moment/moment";
import useDataManager from "../../../hooks/useDataManager";
import { setList } from "../../../store/servicesCallSlice";
import { setShowLoading } from "../../../store/sysSlice";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { endpointName as endpointName } from "../const";

const CreateServices = ({ open, setOpen, list, setList }) => {
  const { addItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (values) => {
    dispatch(setShowLoading(true));
    let data = { ...values };

    http
      .post(`${apiUrl}/${endpointName}`, data)
      .then((res) => {
        data._id = res?.data?.itemId;
        data.stTypeId = 2;
        data.created = moment();

        const newList = addItem(data, list);
        setList(newList);
        dispatch(setShowLoading(false));
        setOpen(false);
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            padding: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Stack spacing={2}>
            <Typography paddingX={2} paddingTop={2} variant="h6">
              {t("CREATE_ORDER")}
            </Typography>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <Stack padding={1} gap={2} justifyContent="center">
            <ClientDetails control={control} errors={errors} />
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <Stack direction="row" gap={2} padding={1}>
            <CustomButton
              variant="outlined"
              title="CLOSE"
              onClick={() => setOpen(false)}
            />
            <CustomButton loading title="SEND" submit />
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default CreateServices;
