import moment from "moment";
import { DATE_TIME_FORMAT } from "../../config/const";
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NullCheck from "../../components/NullCheck";
import { Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  currencyFormat,
  lineText,
  listTolineText,
} from "../../config/functions";

const GroupData = ({
  serviceCallDetailsHandle,
  deleteServicesCall,
  filterlist,
  displaySettings,
  clientsList,
}) => {
  const { t } = useTranslation();
  const [list, setrList] = useState([]);

  useEffect(() => {
    if (displaySettings) {
      const list = Object.keys(displaySettings?.show).filter(
        (key) => displaySettings?.show[key] == true
      );
      setrList(list);
    }
  }, [displaySettings]);

  const Item = ({ title, value, fontSize, column }) => {
    return (
      <NullCheck valueForCheck={value && list.includes(column)}>
        <Stack direction="row" spacing={1}>
          <Typography fontSize={fontSize ?? 14} fontWeight="bold">
            {title ? `${t(title)}:` : ""}
          </Typography>
          <Typography
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
            fontSize={fontSize ?? 14}
          >
            {value}
          </Typography>
        </Stack>
      </NullCheck>
    );
  };

  return (
    <Stack padding={2} spacing={2} direction="row" useFlexGap flexWrap="wrap">
      {filterlist
        ?.sort((a, b) => new Date(a.workDt) - new Date(b.workDt))
        ?.map((call) => {
          const findClient = clientsList?.find(
            (client) => parseInt(client?.guid) === parseInt(call.clientId)
          );
          return (
            <Stack
              width={{ xs: "100%", md: 320 }}
              // maxHeight={300}
              justifyContent="space-between"
              border="2px solid"
              borderColor="primary.main"
              sx={{ cursor: "pointer" }}
              onClick={() =>
                serviceCallDetailsHandle({ ...call, id: call?._id })
              }
            >
              <Box>
                <Stack
                  padding={1}
                  direction="row"
                  alignItems="center"
                  bgcolor="primary.main"
                >
                  <Typography color="primary.contrastText" fontWeight="bold">
                    {`${t("SERVICES_CALL")} - ${call.callId}`}
                  </Typography>
                </Stack>

                <Stack padding={1} spacing={1}>
                  <Item
                    title="CLIENT_CALL_NUMNER"
                    column="clientCallNumner"
                    value={call?.clientCallNumner}
                  />
                  <Item title="CONTACT_NAME" column="name" value={call?.name} />
                  <Item
                    title="COMPANY"
                    column="company"
                    value={findClient?.company ?? ""}
                  />
                  <Item title="ADDR" column="addr" value={call?.addr} />
                  <Item title="TEL" column="tel" value={call?.tel} />
                  <Item
                    title="PRICE"
                    column="price"
                    value={currencyFormat(call?.price)}
                  />
                  <Item
                    title="WORK_DT"
                    column="workDt"
                    value={
                      call?.workDt
                        ? moment(call?.workDt).format(DATE_TIME_FORMAT)
                        : null
                    }
                  />
                  <Item
                    title="NOTES"
                    column="notes"
                    value={lineText(call?.notes)}
                  />

                  <Item
                    title="PROVIDERS"
                    column="providers"
                    value={lineText(call?.providers)}
                  />

                  <NullCheck valueForCheck={list?.includes("contentWithItems")}>
                    <Divider sx={{ bgcolor: "primary.main" }} />
                    <Typography>
                      {listTolineText(call?.contentWithItems)}
                    </Typography>
                  </NullCheck>
                </Stack>
              </Box>

              <Stack
                px={1}
                direction="row"
                borderTop="2px solid"
                borderColor="primary.main"
                alignItems="center"
                justifyContent="space-between"
                bgcolor="secondary.main"
              >
                <NullCheck valueForCheck={call.workDt} nullToDiv>
                  <Typography>
                    {moment(call.workDt).format(DATE_TIME_FORMAT)}
                  </Typography>
                </NullCheck>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteServicesCall(call._id);
                  }}
                >
                  <Delete />
                </IconButton>
              </Stack>
            </Stack>
          );
        })}
    </Stack>
  );
};
export default GroupData;
