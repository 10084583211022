import { useTranslation } from "react-i18next";
import CustomButton from "../../components/CustomButton";
import CustomTextLink from "../../components/CustomTextLink";
import useForms from "../../hooks/useForms";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CheckRole from "../../components/CheckRole";
import { MANAGER } from "../../config/roles";
import SelectInput from "../../components/files/SelectInput";
import {
  PMT_TYPE,
  PMT_TYPE_ID,
  ST_TYPE,
  ST_TYPE_ID,
  endpointNmae,
} from "./const";
import TextInput from "../../components/files/TextInput";
import { listTolineText } from "../../config/functions";
import { onlyNumber } from "../../utils/validations";
import http from "../../http";
import { setShowLoading, setShowMassage } from "../../store/sysSlice";
import { apiUrl, boUrl, webAppUrl } from "../../config/settings";
import NullCheck from "../../components/NullCheck";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import useDataManager from "../../hooks/useDataManager";
import { setList } from "../../store/servicesCallSlice";
import { DATE_TIME_FORMAT } from "../../config/const";
import CustomMenu from "../../components/Menu";
import { Outbox, Summarize } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

const Details = ({ data, setData, list }) => {
  const { editItem } = useDataManager();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const employeeslist = useSelector((state) => state.biz?.data?.employees);
  const biz = useSelector((state) => state.biz?.data);
  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const [empList, setEmpList] = useState([]);
  const [workDt, setWorkDt] = useState(null);
  const [workEndDt, setWorkEndDt] = useState(null);
  const pmtTypeId = watch("pmtTypeId");

  const menuOptions = [
    {
      title: "OPEN_SEVICES_SUMMARY",
      icon: <Summarize />,
      show: true,
      onClick: () => openSummary(),
    },
    {
      title: "SEND_SEVICES_SUMMARY",
      icon: <Outbox fontSize="small" />,
      show: true,
      onClick: () => sendSummary(),
    },
  ];

  useEffect(() => {
    reset(data);

    setEmpList(
      employeeslist?.map((emp) => {
        return { id: emp?.id, name: `${emp?.fn} ${emp?.ln}` };
      })
    );
  }, [employeeslist]);

  useEffect(() => {
    if (data?.workDt) {
      setWorkDt(moment(data?.workDt));
    }
    if (data?.workEndDt) {
      setWorkEndDt(moment(data?.workEndDt));
    }
  }, [data]);

  const onClose = () => {
    setData(false);
  };

  const sendSummary = () => {
    dispatch(setShowLoading(true));
    http
      .get(`${apiUrl}/${endpointNmae}/servicesSummarySendEmail/${data.id}`)
      .then((res) => {
        dispatch(setShowLoading(false));
        if (res.data?.id === 1) {
          dispatch(
            setShowMassage({
              massage: "SEND_SUCCESS",
              type: "success",
            })
          );
        } else if (res.data?.id === -5) {
          dispatch(
            setShowMassage({
              massage: "CLIENT_NO_EMAIL",
              type: "error",
            })
          );
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const openSummary = () => {
    window.open(
      `${webAppUrl}/Services-Summary/${biz.path}/${data.id}`,
      "_blank"
    );
  };

  const onSubmit = (values) => {
    if (moment(workEndDt).isBefore(workDt)) {
      return dispatch(
        setShowMassage({
          massage: "WORK_END_DT_ISBEFORE_WORK_DT_ERROR",
          type: "error",
        })
      );
    }

    dispatch(setShowLoading(true));
    let obj = {
      notes: values?.notes,
      providers: values?.providers,
      providersExpenses: values?.providersExpenses,
      jobDoneDesc: values?.jobDoneDesc,
      stTypeId: values?.stTypeId,
      workDt: workDt,
      workEndDt: workEndDt,
      addr: values?.addr,
      pmtTypeId: values?.pmtTypeId,
      price: values?.price,
      employee: values?.employee,
      orderNum: values?.orderNum,
      invoiceNum: values?.invoiceNum,
      quotationNum: values?.quotationNum,
    };

    http
      .patch(`${apiUrl}/${endpointNmae}/${data?.id}`, obj)
      .then((res) => {
        dispatch(setShowLoading(false));
        const item = {
          _id: data?.id,
          ...data,
          notes: values?.notes ?? data?.notes,
          jobDoneDesc: values?.jobDoneDesc ?? data?.jobDoneDesc,
          stTypeId: values?.stTypeId ?? data?.stTypeId,
          workDt: workDt,
          workEndDt:
            values?.stTypeId === ST_TYPE_ID.CLOSE ? moment() : workEndDt,
          addr: values?.addr ?? data?.addr,
          pmtTypeId: values?.pmtTypeId ?? data?.pmtTypeId,
          providers: values?.providers ?? data?.providers,
          providersExpenses:
            values?.providersExpenses ?? data?.providersExpenses,
          price: values?.price ?? data?.price,
          orderNum: values?.orderNum ?? data?.orderNum,
          invoiceNum: values?.invoiceNum ?? data?.invoiceNum,
          quotationNum: values?.quotationNum ?? data?.quotationNum,
          employee: values?.employee ?? data?.employee,
        };

        const newList = editItem(item, list);
        dispatch(setList(newList));
        onClose();
      })
      .catch((error) => {
        dispatch(setShowLoading(false));
      });
  };

  return (
    <Dialog
      open={data ? true : false}
      onClose={onClose}
      maxWidth="md"
      sx={{
        padding: 0,
        "& .MuiDialog-paper": {
          width: "100%",
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            bgcolor: "white",
            zIndex: 999,
            borderBottom: "1px solid black",
          }}
        >
          <Typography>{`${t("SERVICES_CALL")} - ${data?.callId}`}</Typography>

          <CustomMenu options={menuOptions} />
        </DialogTitle>

        <DialogContent>
          <Stack height="100%" width="100%" padding={1} spacing={1}>
            <Stack
              gap={2}
              direction={{ sx: "column", md: "row" }}
              justifyContent="space-between"
            >
              {/* Client Details */}
              <Stack width="100%" spacing={1}>
                <Typography variant="body2">
                  {`${t("CLIENT_ID")}: ${data?.clientId}`}
                </Typography>

                <NullCheck valueForCheck={data?.clientCallNumner}>
                  <Typography variant="body2">
                    {`${t("CLIENT_CALL_NUMNER")}: ${
                      data?.clientCallNumner ?? ""
                    }`}
                  </Typography>
                </NullCheck>

                <Typography variant="body2">
                  {`${t("CONTACT_NAME")}: ${data?.name}`}
                </Typography>

                <CustomTextLink
                  title={`${t("TEL")}: ${data?.tel}`}
                  href={`tel:${data?.tel}`}
                  fontSize={14}
                />

                <Typography variant="body2">
                  {`${t("CREATED")}: ${moment(data?.created).format(
                    DATE_TIME_FORMAT
                  )}`}
                </Typography>

                <TextInput
                  width="100%"
                  errors={errors}
                  name="addr"
                  title="ADDR"
                  control={control}
                />

                <TextInput
                  width="100%"
                  errors={errors}
                  name="notes"
                  title="NOTES"
                  rows={2}
                  control={control}
                />

                <TextInput
                  width="100%"
                  errors={errors}
                  name="jobDoneDesc"
                  title="JOB_DONE_DESC"
                  rows={2}
                  control={control}
                />

                <Divider />

                <TextInput
                  width="100%"
                  errors={errors}
                  name="providers"
                  title="PROVIDERS"
                  rows={2}
                  control={control}
                />
                <TextInput
                  width="100%"
                  errors={errors}
                  validation={onlyNumber}
                  name="providersExpenses"
                  title="PROVIDERS_EXPENSES"
                  control={control}
                />
              </Stack>

              <Divider orientation="vertical" variant="middle" flexItem />

              {/* Actions */}
              <Stack spacing={1}>
                <CheckRole role={MANAGER}>
                  <SelectInput
                    control={control}
                    errors={errors}
                    name="employee"
                    title="SELECT_EMPLOYEE"
                    options={empList}
                    defaultValue={data?.employee ?? empList[0]?.id}
                  />

                  <Stack>
                    <Typography fontSize={12}>{t("WORK_DT")}:</Typography>
                    <DateTimePicker
                      minDate={moment()}
                      value={workDt}
                      onChange={(e) => setWorkDt(e)}
                    />
                  </Stack>

                  <Stack>
                    <Typography fontSize={12}>{t("WORK_END_DT")}:</Typography>
                    <DateTimePicker
                      // maxDate={moment()}
                      value={workEndDt}
                      onChange={(e) => setWorkEndDt(e)}
                    />
                  </Stack>
                </CheckRole>

                <SelectInput
                  control={control}
                  errors={errors}
                  name="stTypeId"
                  title="ST_TYPE_CALL"
                  options={ST_TYPE}
                  defaultValue={data?.stTypeId ?? ST_TYPE[0]?.id}
                />
              </Stack>

              <CheckRole role={MANAGER}>
                <Divider orientation="vertical" variant="middle" flexItem />

                <Stack spacing={1}>
                  <TextInput
                    width="100%"
                    errors={errors}
                    validation={onlyNumber}
                    name="quotationNum"
                    title="QUOTATION_NUM"
                    control={control}
                  />

                  <TextInput
                    width="100%"
                    errors={errors}
                    validation={onlyNumber}
                    name="orderNum"
                    title="ORDER_NUM"
                    control={control}
                  />

                  <TextInput
                    width="100%"
                    errors={errors}
                    validation={onlyNumber}
                    name="invoiceNum"
                    title="INVOICE_NUM"
                    control={control}
                  />

                  <SelectInput
                    control={control}
                    errors={errors}
                    name="pmtTypeId"
                    title="PMT_TYPE_ID"
                    options={PMT_TYPE}
                    defaultValue={data?.pmtTypeId ?? PMT_TYPE_ID.PAY}
                  />

                  {pmtTypeId === PMT_TYPE_ID.PAY ? (
                    <TextInput
                      width="100%"
                      errors={errors}
                      validation={onlyNumber}
                      name="price"
                      title="PRICE"
                      control={control}
                    />
                  ) : null}
                </Stack>
              </CheckRole>
            </Stack>
            <Divider />
            <Typography>{listTolineText(data?.contentWithItems)}</Typography>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            bgcolor: "white",
            zIndex: 999,
            gap: 2,
            borderTop: "1px solid black",
          }}
        >
          <CustomButton
            variant="outlined"
            title="CLOSE"
            autoFocus
            onClick={onClose}
          />
          <CustomButton title="SAVE" submit />
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default Details;
