import { useTranslation } from "react-i18next";
import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import { DataGrid } from "@mui/x-data-grid";
import Details from "./Details";
import { Delete, Edit } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import { endpointNmae } from "./const";
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NullCheck from "../../components/NullCheck";
import useDataManager from "../../hooks/useDataManager";

const Departments = () => {
  const { removeItem } = useDataManager();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const maxClients = useSelector((state) => state?.biz?.data?.plan?.maxClients);
  const [employees, setEmployees] = useState([]);
  const [list, setList] = useState([]);
  const [departmentDetails, setDepartmentDetails] = useState(null);

  useEffect(() => {
    getData();
    getEmployees();
  }, []);

  const getData = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointNmae}`).then((res) => {
      setList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const getEmployees = () => {
    http.get(`${apiUrl}/employees`).then((res) => {
      setEmployees(res?.data?.data);
    });
  };

  const departmentDetailsHandle = (client) => {
    setDepartmentDetails(client);
  };

  const deleteDepartmentDetail = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_CLIENT",
        onClick: () => {
          dispatch(setShowLoading(true));

          http
            .delete(`${apiUrl}/${endpointNmae}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
              const newList = removeItem(id, list);
              setList(newList);
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Stack width="55%" direction="row" justifyContent="space-between">
          <NullCheck valueForCheck={maxClients > 0} nullToDiv>
            <Typography>
              {isMobile
                ? `${t("QTY")}: ${list?.length} / ${maxClients}`
                : `${t("LINK_QTY")}: ${list?.length} ${t("OF")} ${maxClients}`}
            </Typography>
          </NullCheck>

          <CustomButton
            title="NEW"
            disabled={maxClients > 0 && list?.length >= maxClients}
            onClick={() => departmentDetailsHandle({ id: "new" })}
          />
        </Stack>
      }
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        onRowClick={(e) => setDepartmentDetails(e.row)}
        // checkboxSelection
        disableRowSelectionOnClick
        rowReordering
        disableColumnMenu
        columns={[
          {
            field: "name",
            headerName: t("NAME"),
            width: 150,
            filterable: false,
          },
          {
            field: "manager",
            headerName: t("MANAGER"),
            width: 150,
            filterable: false,
            renderCell: (value) => {
              const findEmp = employees?.find(
                (emp) => emp?._id === value?.row?.manager
              );
              return findEmp ? `${findEmp?.fn} ${findEmp?.ln}` : "";
            },
          },
          {
            field: "edit",
            headerName: t("EDIT"),
            width: 100,
            filterable: false,
            renderCell: (value) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setDepartmentDetails(value?.row);
                  }}
                >
                  <Edit />
                </IconButton>
              );
            },
          },
          {
            field: "delete",
            headerName: t("DELETE"),
            width: 100,
            filterable: false,
            renderCell: (row) => {
              return (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteDepartmentDetail(row.id);
                  }}
                >
                  <Delete />
                </IconButton>
              );
            },
          },
        ]}
        rows={list.map((department) => {
          return {
            ...department,
            id: department?._id,
            name: department?.name,
            manager: department?.manager,
            edit: department?._id,
            delete: department?._id,
          };
        })}
      />

      {departmentDetails ? (
        <Details
          setList={setList}
          list={list}
          employees={employees}
          departmentData={departmentDetails}
          setDepartmentData={setDepartmentDetails}
          getData={getData}
        />
      ) : null}
    </CustomPage>
  );
};
export default Departments;
