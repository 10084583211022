import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;

const CustomMenu = ({ options, disabled }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (onClick) => {
    onClick();
    handleClose();
  };

  return (
    <div>
      <IconButton
        disabled={disabled}
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 10,
            // width: "20ch",
          },
        }}
      >
        {options?.map((option, index) => {
          return (
            option.show && (
              <MenuItem
                disabled={option?.disabled}
                key={index}
                selected={option === "Pyxis"}
                onClick={() => onClickMenuItem(option.onClick)}
              >
                <Stack
                  direction="row"
                  gap={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  {option.icon}
                  <Typography paddingBottom={0.5}>{t(option.title)}</Typography>
                </Stack>
              </MenuItem>
            )
          );
        })}
      </Menu>
    </div>
  );
};

export default CustomMenu;
