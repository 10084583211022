const useDataManager = () => {
  const addItem = (item, list) => {
    let newList = [...list];
    if (item?._id || item?.id) {
      newList.push(item);
    }
    return newList;
  };

  const editItem = (item, list) => {
    let newList = [...list];
    if (item?._id || item?.id) {
      const findIndex = newList.findIndex((i) => i._id === item?._id);
      if (findIndex || findIndex === 0) {
        newList[findIndex] = item;
      }
    }
    return newList;
  };

  const removeItem = (id, list) => {
    let newList = [...list];
    if (id) {
      newList = newList.filter((i) => i._id !== id && i.id !== id);
    }
    return newList;
  };

  return { addItem, editItem, removeItem };
};

export default useDataManager;
