import { useEffect, useState } from "react";
import http from "../../http";
import { endpointNmae, PMT_TYPE_ID, ST_TYPE_ID } from "./const";
import Chart from "../../components/Chart";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Divider, Stack, Typography } from "@mui/material";
import { currencyFormat, sum } from "../../config/functions";
import CheckRole from "../../components/CheckRole";
import { MANAGER } from "../../config/roles";

const ServicesCall = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [dayWork, setDayWork] = useState([]);
  const [weekWork, setWeekWork] = useState([]);
  const [monthWork, setMonthWork] = useState([]);

  useEffect(() => {
    http
      .get(`${endpointNmae}/serviceCall`)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, []);

  useEffect(() => {
    if (data) {
      setDayWork(checkBystType(moment().startOf("day"), moment().endOf("day")));
      setWeekWork(
        checkBystType(moment().startOf("week"), moment().endOf("week"))
      );
      setMonthWork(
        checkBystType(moment().startOf("month"), moment().endOf("month"))
      );
    }
  }, [data]);

  const checkBystType = (start, end) => {
    const list = data.filter((call) =>
      moment(call.workDt || call.created).isBetween(moment(start), moment(end))
    );

    data.map((call) => {
      // console.log("call.callId", call.callId);

      if (call.callId === "240875") console.log("call", call);
      else console.log("no");
    });

    // console.log("list", list);

    if (list?.length > 0) {
      const openServices = list.filter(
        (service) => service.stTypeId === ST_TYPE_ID.OPEN
      );
      const closeServices = list.filter(
        (service) => service.stTypeId === ST_TYPE_ID.CLOSE
      );
      const waitingServices = list.filter(
        (service) => service.stTypeId === ST_TYPE_ID.WAITING
      );

      let totalPrice = list.filter(
        (service) => service.pmtTypeId === PMT_TYPE_ID.PAY
      );
      totalPrice = sum(totalPrice, "price");

      let providersExpenses = sum(list, "providersExpenses");

      let noInvoiceNumber = closeServices.filter(
        (item) => !item.hasInvoiceNum
      )?.length;

      const qty =
        openServices.length + closeServices.length + waitingServices.length;

      const chartData = [
        { id: 1, value: openServices?.length, label: "פתוח" },
        { id: 2, value: closeServices?.length, label: "סגור" },
        { id: 3, value: waitingServices?.length, label: "ממתין" },
      ];
      return {
        chartData,
        totalPrice,
        openServices,
        closeServices,
        waitingServices,
        qty,
        list,
        noInvoiceNumber,
        providersExpenses,
      };
    } else {
      return [];
    }
  };

  if (data.length === 0) return;

  return (
    <Stack spacing={2}>
      <CheckRole role={MANAGER}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          useFlexGap
          flexWrap="wrap"
          spacing={2}
        >
          <Stack
            alignItems="center"
            border={1}
            paddingX={2}
            spacing={1}
            borderColor="primary.main"
          >
            <Typography variant="h5">{t("REVENUE")}</Typography>
            <Typography variant="h6">
              {currencyFormat(monthWork?.totalPrice)}
            </Typography>
          </Stack>

          <Stack
            alignItems="center"
            border={1}
            paddingX={2}
            spacing={1}
            borderColor="primary.main"
          >
            <Typography variant="h5">
              {t("TOTAL_EXPENSES_PROVIDERS")}
            </Typography>
            <Typography variant="h6">
              {currencyFormat(monthWork?.providersExpenses)}
            </Typography>
          </Stack>

          <Stack
            alignItems="center"
            border={1}
            paddingX={2}
            spacing={1}
            borderColor="primary.main"
          >
            <Typography variant="h5">{t("TOTAL_REVENUE")}</Typography>
            <Typography variant="h6">
              {currencyFormat(
                monthWork?.totalPrice - monthWork?.providersExpenses
              )}
            </Typography>
          </Stack>

          <Stack
            alignItems="center"
            border={1}
            paddingX={2}
            spacing={1}
            borderColor="primary.main"
          >
            <Stack alignItems="center">
              <Typography variant="h5">{t("NO_INVOICE_NUMBER")}</Typography>
              <Typography variant="h6">{monthWork?.noInvoiceNumber}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </CheckRole>

      <Stack
        direction={{ xs: "column", md: "row" }}
        useFlexGap
        flexWrap="wrap"
        spacing={2}
      >
        {/* day */}
        <Stack border={1} borderColor="primary.main">
          <Typography textAlign="center" variant="h6">
            קריאות להיום
          </Typography>

          <Divider />

          <Stack
            width="100%"
            height="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack alignItems="center" paddingX={2}>
              <Typography variant="h6">סה"כ</Typography>
              <Typography>{dayWork?.qty}</Typography>
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Chart
              data={dayWork?.chartData}
              size={200}
              outerRadiusSize={90}
              cx={2}
            />
          </Stack>

          <Divider />
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            padding={1}
          >
            {dayWork?.chartData?.map((item) => {
              return <Typography>{`${item.label}: ${item.value}`}</Typography>;
            })}
          </Stack>
        </Stack>

        {/* week */}
        <Stack border={1} borderColor="primary.main">
          <Typography textAlign="center" variant="h6">
            קריאות לשבוע
          </Typography>

          <Divider />

          <Stack
            width="100%"
            height="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack alignItems="center" paddingX={2}>
              <Typography variant="h6">סה"כ</Typography>
              <Typography>{weekWork?.qty}</Typography>
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Chart
              data={weekWork?.chartData}
              size={200}
              outerRadiusSize={90}
              cx={2}
            />
          </Stack>

          <Divider />
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            padding={1}
          >
            {weekWork?.chartData?.map((item) => {
              return <Typography>{`${item.label}: ${item.value}`}</Typography>;
            })}
          </Stack>
        </Stack>

        {/* month */}
        <Stack border={1} borderColor="primary.main">
          <Typography textAlign="center" variant="h6">
            קריאות לחודש
          </Typography>

          <Divider />

          <Stack
            width="100%"
            height="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack alignItems="center" paddingX={2}>
              <Typography variant="h6">סה"כ</Typography>
              <Typography>{monthWork?.qty}</Typography>
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Chart
              data={monthWork?.chartData}
              size={200}
              outerRadiusSize={90}
              cx={2}
            />
          </Stack>

          <Divider />
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            padding={1}
          >
            {monthWork?.chartData?.map((item) => {
              return <Typography>{`${item.label}: ${item.value}`}</Typography>;
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default ServicesCall;
