import CustomPage from "../../components/CustomPage";
import { useEffect, useState } from "react";
import { apiUrl } from "../../config/settings";
import { useDispatch, useSelector } from "react-redux";
import http from "../../http";
import { setDeleteMassage, setShowLoading } from "../../store/sysSlice";
import Association from "./Association";
import { endpointName } from "./const";
import { Stack } from "@mui/material";
import CreateServices from "./create";
import Header from "./Header";
import TableData from "./TableData";
import GroupData from "./GroupData";
import useDataManager from "../../hooks/useDataManager";

const OrderTaxi = () => {
  const dispatch = useDispatch();
  const { removeItem } = useDataManager();
  const employeeslist = useSelector((state) => state?.biz?.data?.employees);
  const [itemsList, setItemsList] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [servicesCallDetails, setServicesCallDetails] = useState(null);
  const [openCreateServices, setOpenCreateServices] = useState(false);
  const [displaySettings, setDisplaySettings] = useState(null);

  useEffect(() => {
    getClientList();
    getCallsList();

    let settings = localStorage.getItem("orderTaxiDisplaySettings");
    settings = JSON.parse(settings);
    if (settings) {
      setDisplaySettings(settings);
    } else {
      const data = {
        type: "cards",
        show: {
          name: true,
          tel: true,
          addr: true,
          notes: true,
          clientCallNumner: true,
        },
      };
      setDisplaySettings(data);
      localStorage.setItem("orderTaxiDisplaySettings", JSON.stringify(data));
    }
  }, []);

  const getCallsList = () => {
    dispatch(setShowLoading(true));
    http.get(`${apiUrl}/${endpointName}`).then((res) => {
      setItemsList(res?.data?.data);
      dispatch(setShowLoading(false));
    });
  };

  const getClientList = () => {
    http
      .get(`${apiUrl}/clients`)
      .then((res) => {
        setClientsList(res?.data?.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const orderDetailsHandle = (order) => {
    setServicesCallDetails(order);
  };

  const deleteOrder = (id) => {
    dispatch(
      setDeleteMassage({
        massage: "DELETE_SERVICES_CALL",
        onClick: () => {
          dispatch(setShowLoading(true));
          http
            .delete(`${apiUrl}/${endpointName}/${id}`)
            .then((res) => {
              dispatch(setShowLoading(false));
            })
            .catch((error) => {
              dispatch(setShowLoading(false));
            });
        },
      })
    );
  };

  const cancelOrder = (orderId) => {
    http
      .patch(`${apiUrl}/${endpointName}/cancelOrder/${orderId}`)
      .then((res) => {
        if (res?.data?.id === 1) {
          const newList = removeItem(orderId, itemsList);
          setItemsList(newList);
          setServicesCallDetails(null);
        }
      })
      .catch((error) => {});
  };

  return (
    <CustomPage
      hidePadding
      header={
        <Header
          setDisplaySettings={setDisplaySettings}
          clientsList={clientsList}
          setItemsList={setItemsList}
          getCallsList={getCallsList}
          employeeslist={employeeslist}
          setOpenCreateServices={setOpenCreateServices}
        />
      }
    >
      <Stack height="100%">
        {displaySettings?.type === "table" ? (
          <TableData
            displaySettings={displaySettings}
            clientsList={clientsList}
            orderDetailsHandle={orderDetailsHandle}
            deleteOrder={deleteOrder}
            filterlist={itemsList}
          />
        ) : (
          <GroupData
            cancelOrder={cancelOrder}
            displaySettings={displaySettings}
            clientsList={clientsList}
            orderDetailsHandle={orderDetailsHandle}
            deleteOrder={deleteOrder}
            filterlist={itemsList}
          />
        )}
      </Stack>

      {servicesCallDetails ? (
        <Association
          cancelOrder={cancelOrder}
          setList={setItemsList}
          list={itemsList}
          data={servicesCallDetails}
          setData={setServicesCallDetails}
          getData={getCallsList}
        />
      ) : null}

      {openCreateServices ? (
        <CreateServices
          setList={setItemsList}
          list={itemsList}
          clients={clientsList}
          open={openCreateServices}
          setOpen={setOpenCreateServices}
        />
      ) : null}
    </CustomPage>
  );
};
export default OrderTaxi;
