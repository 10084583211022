import CustomPage from "../../components/CustomPage";
import * as React from "react";
import { Stack } from "@mui/material";
import ServicesCall from "./ServicesCall";
import NullCheck from "../../components/NullCheck";
import useCheckData from "../../hooks/CheckData";
import { PLUGIN_NAME } from "../../config/const";

const Dashboard = () => {
  const { hasPlugin } = useCheckData();

  return (
    <CustomPage>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "start" }}
        alignItems={{ xs: "center", md: "start" }}
        gap={5}
      >
        <NullCheck valueForCheck={hasPlugin(PLUGIN_NAME.SERVICES_CALL)}>
          <ServicesCall />
        </NullCheck>
      </Stack>
    </CustomPage>
  );
};
export default Dashboard;
