import { Scheduler } from "@aldabil/react-scheduler";
import CustomPage from "../../components/CustomPage";
import { he } from "date-fns/locale";
import heIL from "./translation/heIL.json";
import { useEffect, useState } from "react";
import CustomEditor from "./CustomEditor";
import { apiUrl } from "../../config/settings";
import http from "../../http";
import { endpointName } from "./const";
import { useSelector } from "react-redux";
import { ROLES_ID } from "../../config/roles";
import NullCheck from "../../components/NullCheck";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../../config/const";
import CustomViewer from "./CustomViewer";
import { Stack } from "@mui/material";
import { month } from "./month";
import { week } from "./week";

const Schedule = () => {
  const user = useSelector((state) => state.userSettings?.user);
  const [employeeId, setEmployeeId] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [viewer, setViewer] = useState(null);
  const [list, setList] = useState([]);
  const [showSchedule, setShowSchedule] = useState(true);

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    getAppointmentsList();
  }, [user]);

  useEffect(() => {
    setShowSchedule(false);

    const timer = setTimeout(() => {
      setShowSchedule(true);
    }, 10);

    return () => clearTimeout(timer);
  }, [list]);

  const getClients = () => {
    http.get(`${apiUrl}/clients`).then((res) => {
      setClientList(res?.data?.data);
    });
  };

  const getAppointmentsList = () => {
    if (user?.userRole === ROLES_ID.EMPLOYEES) {
      http.get(`${apiUrl}/${endpointName}/${employeeId}`).then((res) => {
        const list = res?.data?.data?.map((item) => {
          return {
            event_id: item?._id,
            title: item?.clientName,
            start: new Date(item?.startDt),
            end: new Date(item?.endDt),
          };
        });
        setList(list);
      });
    } else if (
      user?.userRole === ROLES_ID.MANAGER ||
      user?.userRole === ROLES_ID.SU_ADMIN
    ) {
      http.get(`${apiUrl}/${endpointName}`).then((res) => {
        const list = res?.data?.data?.map((item) => {
          const startDt = moment(item?.startDt);
          const endDt = moment(item?.endDt);
          return {
            ...item,
            event_id: item?._id,
            title: item?.clientName,
            start: new Date(startDt),
            end: new Date(endDt),
          };
        });
        setList(list);
      });
    }
  };

  const openEditor = (item) => {
    return (
      <CustomEditor
        getData={getAppointmentsList}
        item={item}
        clientList={clientList}
      />
    );
  };
  const openViewer = (item, close) => {
    const data = { ...item, close };
    return (
      <CustomEditor
        getData={getAppointmentsList}
        item={data}
        clientList={clientList}
      />
    );
    // return <CustomViewer item={data} openEditor={openEditor} />;
  };

  return (
    <CustomPage hidePadding>
      <NullCheck valueForCheck={showSchedule}>
        <Stack height="100%">
          <Scheduler
            // height="20"
            view="week"
            locale={he}
            // disableViewNavigator
            month={month}
            week={week}
            direction="rtl"
            timeZone="IST"
            translations={heIL}
            agenda={false}
            hourFormat="24"
            stickyNavigation
            customViewer={(e, close) => openViewer(e, close)}
            customEditor={openEditor}
            events={list}
          />
        </Stack>
      </NullCheck>
    </CustomPage>
  );
};
export default Schedule;
