import { Stack } from "@mui/material";
import TextInput from "../../../components/files/TextInput";
import { celIsraeli } from "../../../utils/validations";

const ClientDetails = ({ errors, control }) => {
  return (
    <Stack spacing={2}>
      <TextInput
        width={{ xs: "100%", md: "auto" }}
        errors={errors}
        name="clientTel"
        title="TEL"
        required
        validation={celIsraeli}
        control={control}
      />
      <TextInput
        width={{ xs: "100%", md: "auto" }}
        errors={errors}
        name="collectionAddr"
        title="COLLECTION_ADDR"
        required
        control={control}
      />
    </Stack>
  );
};
export default ClientDetails;
